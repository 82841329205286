<template>
    <div>
        <div>
            <b>ASK:</b>{{ monitorItem.priceAsk.toFixed(monitorItem.digits) }}
        </div>
        <div>
            <b>BID:</b>{{ monitorItem.priceBid.toFixed(monitorItem.digits) }}
        </div>
    </div>
</template>
<script>
export default {
    name: "TooltipTable",
    props: {
        monitorItem: {
            type: Object,
            default: () => ({}),
        },
    },
};
</script>
<style lang="scss" scoped>
.tooltip-table,
.tooltip-data {
    border: 1px solid white;
    border-collapse: collapse;
}

.tooltip-data {
    padding: 0px 5px 0px 5px;
}
</style>
