export const TutorialConstant = {
    "ib-monitor": {
        pageComponent: {
            monitor: {
                step: 1,
                title: "Page Component 1/3",
                intro: "This is the section where trade summaries are presented from various perspectives, curated specifically for the chosen user.",
            },
            exposure: {
                step: 2,
                title: "Page Component 2/3",
                intro: "This is the section where the exposure chart will be generated, displaying data tailored to the selected profile.",
            },
            profile: {
                step: 3,
                title: "Page Component 3/3",
                intro: "This is the section where various profiles, each with unique sales/user parameters, can be created, edited, and removed.",
            },
        },
        profileManipulation: {
            profileName: {
                step: 4,
                title: "Profile Manipulation 1/6",
                intro: "This section shows the name of current selected profile. Default profile name cannot be modified.",
            },
            profileEdition: {
                step: 5,
                title: "Profile Manipulation 2/6",
                intro: "profile edition icons",
            },
            userSalesParam: {
                step: 6,
                title: "Profile Manipulation 3/6",
                intro: "users and sales parameter, notify auth user that only number and comma is allowed",
            },
            revertParams: {
                step: 7,
                title: "Profile Manipulation 4/6",
                intro: "revert param, if user key in param in the last step, welcome user to try and revert to empty param to test the functionality",
            },
            saveAsNew: {
                step: 8,
                title: "Profile Manipulation 5/6",
                intro: "ask user to try this function or skip it",
            },
            profileList: {
                step: 9,
                title: "Profile Manipulation 6/6",
                intro: "This is where profile list can be view and select.",
            },
        },
    },
    "home-page-intro": {
        homeTutorialIcon: {
            title: "Tutorial Icon",
            intro: "You can click this icon to start this tutorial again after finishing the tutorial. \
            Some dialog has its own tutorial, click to start the tutorial for that section.",
        },
        monitorHomeTutorial: {
            title: "Book Monitor",
            intro: "This is the section where trade summaries are presented from various perspectives, curated specifically for the chosen book.",
        },
        allBookHomeTutorial: {
            title: "All Books Table",
            intro: "This is the section where the brief information is presented for all books. Selected book can be changed by clicking the book name.",
        },
        exposureHomeTutorial: {
            title: "Exposure Chart",
            intro: "This is the section where the exposure chart will be generated, displaying data tailored to the selected book.",
        },
        profileHomeTutorial: {
            title: "Profile Manipulation",
            intro: "This section shows the name of current selected profile. Default profile name cannot be modified.",
        },
        symbolSearch: {
            title: "Symbol Search",
            intro: "Click this icon allows you to initiate symbol searching process.",
        },
        "home-table": {
            title: "Monitor Table",
            intro: "In this section, you can click the <b>header</b> to sort table data,\
            click the <b>data row</b> in the table to open the <b>Symbol Detail Dialog</b>.\
            To change the <b>pagination</b> of the table, use the select box and left right button \
            which are located at the <b>bottom right corner</b> of this section",
        },
        symbolEODDialog: {
            title: "EOD Symbol Price Map",
            intro: "By clicking the symbol, you can open the dialog which shows the EOD Symbol Price Map",
        },
        monitorFunctions: {
            title: "Monitor Functions",
            intro: "The excel icon allows you to download the data shown on the data table as a csv file.\
            Using the time interval menu can change the socket message sending duration.",
        },
        allBookFunction: {
            title: "All Book Functions",
            intro: "By click the blue icon, you can check the historical daily summary in a dialog.\
             The excel icon allows you to download the data shown on the data table as a csv file.\
            Using the time interval menu can change the socket message sending duration.",
        },
    },
    homeSymbolDialog: {
        symbolTabChanging: {
            title: "Tabs",
            intro: "By clicking the tab, you can check different aspect of the symbol information.",
        },
        symbolTabSearch: {
            title: "Symbol Search",
            intro: "This is an area where you can typing in your wanted login to search.\
            It can be part of the login, all matching login will be below in the data table.",
        },
        symbolDetailTable: {
            title: "symbol Detail Table",
            intro: "In this section, you can click the <b>header</b> to sort table data,\
            click the <b>login</b> in the table to open the <b>Login Detail Dialog</b>.\
            To change the <b>pagination</b> of the table, use the select box and left right button \
            which are located at the <b>bottom right corner</b> of this section",
        },
    },
    homeSymbolSnapshotDialog: {
        symbolTabTimeSelection: {
            title: "Time Selection",
            intro: "Click to selected wanted date and time. Then press FETCH to retrieve data on the selected time.",
        },
        symbolTabSnapshotTable: {
            title: "Snapshot Table",
            intro: "This table is used to show the difference between selected time and the current time in long, short and net lot.",
        },
    },
    loginTradesDialog: {
        loginTradesDesc: {
            title: "Login Details",
            intro: "General information about the login will listed in the tiles.",
        },
        loginTradesRefresh: {
            title: "Refresh Data",
            intro: "Click the refresh icon to retrieve the updated data",
        },
        loginTradesExpansion: {
            title: "Trades Expansion",
            intro: "Open and close trades will be listed in two different expansion tab.\
            On default, the open trades expansion is open. By clicking the header, to change the expansion open status.",
        },
        loginTradesCalculationReference: {
            title: "Calculation Reference",
            intro: "By clicking the text, the calculation will be shown below and can be used to check how the profit fields are calculated.",
        },
        loginTradesDetail: {
            title: "Login Trades Table",
            intro: "In this section, all trades and their detail made by the login will be listed in the table.",
        },
    },
    loginInfoDialog: {
        loginGroupChangeHistory: {
            title: "Group Change History",
            intro: "In this section, you will be able to see all group change history of the selected login.",
        },
        loginStatistic: {
            title: "Statistic Chart",
            intro: "the chart that shows the statistic in different aspect will be shown here.",
        },
    },
    alertPageIntro: {
        alertGridElement: {
            title: "Alert Window",
            intro: "Alert window contains either chart or table with specific type of information.\
             The window can be resized to desired size and dragged to desired position. \
             To drag the window, just click on the window title and move to desired location.",
        },
        alertRefresh: {
            title: "Alert Data Refresh",
            intro: "By click this icon, the newest data will be manually fetched and display on the table.\
            During the fetching data process, the icon will be in loading state and disabled for re-fetching.",
        },
        alertCalender: {
            title: "Date Selection",
            intro: "Click the icon to select wanted date and time for the alert table.",
        },
        alertDownload: {
            title: "Download Data",
            intro: "To download data on the table, click the icon and the download process will start. \
            When there is no data on the table, this icon will be disabled for clicking.",
        },
        alertConfig: {
            title: "Alert Data Configuration",
            intro: "To check the data configuration, click this icon and a dialog will be shown with config information.",
        },
        alertClose: {
            title: "Close Window",
            intro: "Clicking this icon allows to remove current window from the page.",
        },
        alertBookSelection: {
            title: "Book Selection",
            intro: "After clicking this button, a drop down menu will be shown for book selection.",
        },
        "vue-resizable-handle": {
            title: "Window Size Resize",
            intro: "You are allow to resize the window by click and drag the bottom corner of the window.",
        },
        alertPeriod: {
            title: "Auto Refresh Period",
            intro: "By change the value of period, the page will fetch new data automatically base on the chosen value.",
        },
        alertColumnNumber: {
            title: "Page Grid Column Size",
            intro: "This section is used for auto page grid resize function, changing this value will result in different page grid columns.",
        },
        alertChangeApply: {
            title: "Auto Resize",
            intro: "Click this allows you to resize all windows in this page base on you chosen column size and window added order.",
        },
        alertRevert: {
            title: "Revert Change",
            intro: "If there any position changes to the any window's position or size, you are allowed to revert the change back to how it was recorded in your profile.",
        },
        alertAddNewChart: {
            title: "Add New Window",
            intro: "Using this dropdown menu, you are allowed to add new window into the page.",
        },
        alertSaveChange: {
            title: "Save Changes",
            intro: "After changing the window layout, you can use this button to save the layout to your profile.",
        },
        alertPageProfile: {
            title: "Page Profile",
            intro: "To manage or change profile, click this button and navigate through the dropdown menu.",
        },
    },
    searchPageIntro: {
        searchFilter: {
            title: "Search Filter",
            intro: "This is the section where you cna customize your search preferences.\
            Default Search options are pre-defined for all users.",
        },
        moreSearchFilter: {
            title: "More Filter Options",
            intro: "After Click MORE button, more search options will be listed below.",
        },
        searchButton: {
            title: "Search Button",
            intro: "This button allows to fetch the data from database that meets your search filter.",
        },
        searchDownload: {
            title: "Search Download",
            intro: "After clicking REQUEST CSV button, a request will be sent. \
            Once the backend receive the request, a csv file will be generated and link will be sent to your email address once ready. \
            The generation may take various time depends on the data complexity, thus please bear some time for the csv file to be generated. \
            Also, you can check the status of csv file generation in DOWNLOAD REQUEST STATUS tab.",
        },
        searchTable: {
            title: "Result Data Table",
            intro: "After clicking search button, the data will be fetched. Once received, the data will be displayed in the table below.",
        },
        searchDownloadTable: {
            title: "Search Download Table",
            intro: "In this section, you will be able to check all of previous download requests. \
            Meanwhile you can check the processing status and corresponding search filter of such download requests ",
        },
        searchDownloadRefresh: {
            title: "Refresh Download Status",
            intro: "By clicking this button, a request will be sent out to check the latest update on previous download requests. \
            If there's any update, the data on the table will be changed.",
        },
    },
    lpPageIntro: {
        positionTable: {
            title: "Current Position Table",
            intro: "In this section, you will be able to check the selected LP position information in different aspects.",
        },
        lpChange: {
            title: "Change LP",
            intro: "To change to wanted LP, click and select the desired LP name in the dropdown list.",
        },
        lpRefresh: {
            title: "Refresh Data",
            intro: "You can use this button to manually refresh the data on the table.",
        },
        intervalChange: {
            title: "Auto Refresh Interval",
            intro: "Change the value of the interval allows you to re-fetch data base on the value.",
        },
        accountTable: {
            title: "Account Info Table",
            intro: "In this table, you can view the information about the account for LP",
        },
        accountFunctions: {
            title: "Functions",
            intro: "These functional tools work just like the ones on the top right corner of current position table.",
        },
        positionRecTable: {
            title: "Position Rec",
            intro: "This section shows the position information recorded and calculated by lp and pk. \
            The difference can be used to verify the accuracy of the system.",
        },
        positionRecLastCheck: {
            title: "Last Checked Time",
            intro: "The last checked and calculated time will be shown here.",
        },
        symbolWithBreakTable: {
            title: "Symbol With Break Table",
            intro: "All symbols that have position difference between lp and pk will be showed here.",
        },
        symbolWithoutBreakTable: {
            title: "Symbol Without Break Table",
            intro: "Symbol without break will be showed here",
        },
        positionRecRefresh: {
            title: "Refresh",
            intro: "To retrieve the latest position rec information, use this refresh button.",
        },
    },
};
