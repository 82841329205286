var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"fullscreen":_vm.fullscreen,"width":_vm.dialogWidth,"hide-overlay":true,"persistent":true},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('v-card',{staticClass:"pt-0"},[_c('v-app-bar',{staticStyle:{"background":"rgb(26, 57, 88)"},attrs:{"flat":"","color":"rgba(0, 0, 0, 0)"}},[_c('v-toolbar-title',{staticClass:"text-h6 white--text pl-0"},[_vm._v(_vm._s(_vm.login)+" Summary")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",staticStyle:{"display":"block","float":"right"},attrs:{"dark":"","color":"primary"},on:{"click":_vm.startTutorial}},'v-icon',attrs,false),on),[_vm._v("mdi-school")])]}}])},[_c('span',[_vm._v("Click to start tutorial")])]),(!_vm.VUETIFY_BREAKPOINT.mobile)?_c('v-btn',{attrs:{"color":"white","icon":""},on:{"click":function($event){_vm.fullscreen = !_vm.fullscreen}}},[_c('v-icon',[_vm._v("mdi-arrow-expand")])],1):_vm._e(),_c('v-btn',{attrs:{"color":"white","icon":""},on:{"click":function($event){_vm.visible = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-tabs',[(_vm.showTrades)?_c('v-tab',{on:{"click":function($event){return _vm.changeView('trades')}}},[_vm._v("Trades")]):_vm._e(),(_vm.showSummary)?_c('v-tab',{on:{"click":function($event){return _vm.changeView('info')}}},[_vm._v("Info")]):_vm._e(),(_vm.showLoginSnapshot)?_c('v-tab',{on:{"click":function($event){return _vm.changeView('snapshot')}}},[_vm._v(" Login Snapshot")]):_vm._e()],1),(_vm.loadSummary)?_c('v-row',{staticClass:"pa-4 pb-0"},_vm._l((_vm.loginSummary),function(value,name,index){return _c('v-col',{key:index,attrs:{"cols":"2"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"344"}},[_c('v-card-text',[_c('div',[_vm._v(" "+_vm._s(name === "total_volume_usd" ? "TOTAL VOLUME(USD)" : name .toUpperCase() .replace("_", " "))+" ")]),_c('p',{staticClass:"text text--primary"},[_vm._v(_vm._s(value))])])],1)],1)}),1):_vm._e(),(_vm.loadSummary)?_c('v-row',{staticClass:"pa-4 pt-0 pb-0"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Group change history ")]),_c('v-data-table',{attrs:{"headers":_vm.loginGroupChangeHeader,"items":_vm.loginGroupChangeData,"header-props":{ sortIcon: null },"mobile-breakpoint":0,"id":"loginGroupChangeHistory"},scopedSlots:_vm._u([{key:"item.server_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toServerName(item.server_id))+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toServerTime(item.created_at))+" ")]}}],null,false,1996053435)})],1)],1)],1):_vm._e(),(_vm.loadSummary && _vm.showChart)?_c('v-row',{staticClass:"pa-4 pt-0"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v("Statistic Chart")]),_c('LoginHistoryChart',{attrs:{"dom":'alert-' + _vm.login,"loginValue":_vm.selectedLogin,"id":"loginStatistic"}})],1)],1)],1):_vm._e(),(_vm.loadTrades)?_c('LoginTradesTable',{attrs:{"loginValue":_vm.fullLogin,"server":_vm.server,"symbol":_vm.symbol}}):_vm._e(),_c('v-card-actions'),(_vm.loadSnapshot)?_c('SnapshotTab',{attrs:{"target":"login","book":_vm.book,"server":_vm.server,"login":_vm.login,"symbol":_vm.symbol}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }