<template>
    <v-card class="elevation-0">
        <v-list-item one-line>
            <v-list-item-content style="padding: 0px">
                <div class="overline">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on"> {{ name }} </span>
                        </template>
                        <span>{{ description }}</span>
                    </v-tooltip>
                    <v-list-item-title class="flex-title">
                        <v-icon dense :color="color" small>
                            {{ icon }}
                        </v-icon>
                        {{ numberWithCommas(roundDecimal(value, 2)) }}
                    </v-list-item-title>
                </div>
            </v-list-item-content>
        </v-list-item>
    </v-card>
</template>
<script>
import { helper } from "@components/mixins/helper";
export default {
    mixins: [helper],
    props: {
        cols: { type: Number, default: 4 },
        name: { type: String, default: "" },
        value: { type: Number, default: -1 },
        description: { type: String, default: "-1" }
    },
    data() {
        return {
            color: "",
            initialvalue: 0,
            compare: Object
        };
    },
    computed: {
        icon() {
            return this.color === "red"
                ? "mdi-arrow-down-bold"
                : this.color === "green"
                    ? "mdi-arrow-up-bold"
                    : "";
        }
    },
    created() {},
    mounted() {
        // check value with current and previous, decide which icon to show
        this.compare = setInterval(() => {
            if (parseFloat(this.value) > parseFloat(this.initialvalue)) {
                this.color = "green";
            } else if (parseFloat(this.value) < parseFloat(this.initialvalue)) {
                this.color = "red";
            } else {
                this.color = "";
            }
            this.initialvalue = this.value;
        }, 900);
    },
    destroyed() {
        clearInterval(this.compare);
    }
};
</script>
<style lang="scss" scoped>
.flex-title {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 22px;
    overflow: hidden;
}
</style>
