var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pb-5"},[_c('v-row',{staticClass:"ma-0",attrs:{"id":"symbolTabTimeSelection"}},[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"5"}},[_c('v-datetime-picker',{attrs:{"label":"Target Datetime (MT4 server time)","hide-details":"","dense":"","datePickerProps":{ max: _vm.getMt4Date() }},model:{value:(_vm.selectedTime),callback:function ($$v) {_vm.selectedTime=$$v},expression:"selectedTime"}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"3"}},[_c('v-btn',{staticClass:"mt-5",attrs:{"small":"","color":"primary","loading":_vm.loading,"disabled":_vm.isFetchingDisabled},on:{"click":function($event){return _vm.fetchSnapshotRecord()}}},[_vm._v("Fetch")])],1)],1),_c('v-data-table',{attrs:{"headers":_vm.snapshotHeader,"loading":_vm.loading,"items":_vm.computedSnapshotData,"header-props":{ sortIcon: null },"mobile-breakpoint":0,"hide-default-footer":"","disable-sort":"","id":"symbolTabSnapshotTable","no-data-text":"No snapshot file found for the selected datetime, please choose another time."},scopedSlots:_vm._u([{key:"item.long",fn:function(ref){
var item = ref.item;
return [(item.long.includes('u'))?_c('span',[_vm._v(_vm._s(item.long.replace("u", ""))),_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-arrow-up-thin")])],1):(item.long.includes('d'))?_c('span',[_vm._v(_vm._s(item.long.replace("d", ""))),_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-arrow-down-thin")])],1):_c('span',[_vm._v(_vm._s(item.long))])]}},{key:"item.short",fn:function(ref){
var item = ref.item;
return [(item.short.includes('u'))?_c('span',[_vm._v(_vm._s(item.short.replace("u", ""))),_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-arrow-up-thin")])],1):(item.short.includes('d'))?_c('span',[_vm._v(_vm._s(item.short.replace("d", ""))),_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-arrow-down-thin")])],1):_c('span',[_vm._v(_vm._s(item.short))])]}},{key:"item.net",fn:function(ref){
var item = ref.item;
return [(item.net.includes('u'))?_c('span',[_vm._v(_vm._s(item.net.replace("u", ""))),_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-arrow-up-thin")])],1):(item.net.includes('d'))?_c('span',[_vm._v(" "+_vm._s(item.net.replace("d", ""))+" "),_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-arrow-down-thin")])],1):_c('span',[_vm._v(_vm._s(item.net))])]}},{key:"item.realized",fn:function(ref){
var item = ref.item;
return [(item.realized.includes('u'))?_c('span',[_vm._v(_vm._s(item.realized.replace("u", ""))),_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-arrow-up-thin")])],1):(item.realized.includes('d'))?_c('span',[_vm._v(_vm._s(item.realized.replace("d", ""))),_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-arrow-down-thin")])],1):_c('span',[_vm._v(_vm._s(item.realized))])]}},{key:"item.unrealized",fn:function(ref){
var item = ref.item;
return [(item.unrealized.includes('u'))?_c('span',[_vm._v(_vm._s(item.unrealized.replace("u", ""))),_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-arrow-up-thin")])],1):(item.unrealized.includes('d'))?_c('span',[_vm._v(_vm._s(item.unrealized.replace("d", ""))),_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-arrow-down-thin")])],1):_c('span',[_vm._v(_vm._s(item.unrealized))])]}},{key:"item.dailyNet",fn:function(ref){
var item = ref.item;
return [(item.dailyNet.includes('u'))?_c('span',[_vm._v(_vm._s(item.dailyNet.replace("u", ""))),_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-arrow-up-thin")])],1):(item.dailyNet.includes('d'))?_c('span',[_vm._v(_vm._s(item.dailyNet.replace("d", ""))),_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-arrow-down-thin")])],1):_c('span',[_vm._v(_vm._s(item.dailyNet))])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }