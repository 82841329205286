<template>
    <div>
        <v-dialog
            v-model="visible"
            :fullscreen="fullscreen"
            height="1000"
            :width="dialogWidth"
            :hide-overlay="true"
            :persistent="true"
        >
            <v-card class="pt-0">
                <v-app-bar
                    flat
                    color="rgba(0, 0, 0, 0)"
                    style="background: rgb(26, 57, 88)"
                >
                    <v-toolbar-title class="text-h6 white--text pl-0"
                        >Symbol: {{ symbol }} Detail</v-toolbar-title
                    >

                    <v-spacer></v-spacer>
                    <template
                        v-if="!VUETIFY_BREAKPOINT.mobile && allowDownloadAll"
                    >
                        <v-btn
                            small
                            dark
                            color="primary"
                            dense
                            class="mr-1"
                            :loading="allSymbolDownloadLoading"
                            @click="downloadAllSymbolSnapshot"
                            >Download All</v-btn
                        >
                    </template>
                    <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                v-if="!VUETIFY_BREAKPOINT.mobile"
                                dark
                                v-bind="attrs"
                                v-on="on"
                                color="primary"
                                class="mr-2"
                                style="display: block; float: right"
                                @click="startTutorial"
                                >mdi-school</v-icon
                            >
                        </template>
                        <span>Click to start tutorial</span>
                    </v-tooltip>
                    <v-btn
                        v-if="!VUETIFY_BREAKPOINT.mobile"
                        color="white"
                        icon
                        @click="toggleFullscreen"
                    >
                        <v-icon>mdi-arrow-expand</v-icon>
                    </v-btn>
                    <v-btn color="white" icon @click="handleClose">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-app-bar>

                <v-tabs v-model="tab" id="symbolTabChanging">
                    <v-tab v-on:click="changeView('trades')">Trades</v-tab>
                    <v-tab
                        v-on:click="changeView('snapshot')"
                        v-if="showSymbolSnapshot && isNotIBPage"
                    >
                        Symbol Snapshot</v-tab
                    >
                </v-tabs>
                <template v-if="loadTrades">
                    <!-- PK副表的搜索 -->
                    <v-text-field
                        class="mr-4 mt-n2 ml-4 1mb-5"
                        v-model="searchLogin"
                        append-icon="mdi-search"
                        label="Search Login"
                        single-line
                        hide-details
                        id="symbolTabSearch"
                    >
                    </v-text-field>

                    <!-- PK表副表逻辑 -->
                    <v-data-table
                        id="symbolDetailTable"
                        dense
                        :search="searchLogin"
                        :headers="subHeader"
                        :items="computedSymbolDetailObject"
                        :loading="tableLoading"
                        item-key="login"
                        :items-per-page="15"
                        fixed-header
                        :customSort="customSort"
                        :header-props="{ sortIcon: null }"
                        :mobile-breakpoint="0"
                        :class="{
                            'fix-first-col': VUETIFY_BREAKPOINT.mobile,
                        }"
                        :footer-props="{
                            itemsPerPageOptions: [10, 15],
                            'items-per-page-text': '',
                        }"
                        v-sortable-table="{
                            onEnd: sortSubHeadersAndUpdateTheKey,
                        }"
                        :key="anIncreasingNumberForSub"
                    >
                        <template v-slot:header.longPositions="{ header }">
                            {{ header.text }}
                            <v-icon
                                size="18"
                                color="orange"
                                style="position: absolute"
                                v-if="showSumUp"
                                @click.stop="
                                    () =>
                                        calculateTooltipNumber(
                                            header.text,
                                            header.value
                                        )
                                "
                                >mdi-calculator</v-icon
                            >
                        </template>

                        <template v-slot:header.shortPositions="{ header }">
                            {{ header.text }}
                            <v-icon
                                size="18"
                                color="orange"
                                style="position: absolute"
                                v-if="showSumUp"
                                @click.stop="
                                    () =>
                                        calculateTooltipNumber(
                                            header.text,
                                            header.value
                                        )
                                "
                                >mdi-calculator</v-icon
                            >
                        </template>
                        <template v-slot:header.dailyNet="{ header }">
                            {{ header.text }}
                            <v-icon
                                size="18"
                                color="orange"
                                style="position: absolute"
                                v-if="showSumUp"
                                @click.stop="
                                    () =>
                                        calculateTooltipNumber(
                                            header.text,
                                            header.value
                                        )
                                "
                                >mdi-calculator</v-icon
                            >
                        </template>
                        <template v-slot:header.floatingProfitEOD="{ header }">
                            {{ header.text }}
                            <v-icon
                                size="18"
                                color="orange"
                                style="position: absolute"
                                v-if="showSumUp"
                                @click.stop="
                                    () =>
                                        calculateTooltipNumber(
                                            header.text,
                                            header.value
                                        )
                                "
                                >mdi-calculator</v-icon
                            >
                        </template>
                        <template v-slot:header.closedProfitEOD="{ header }">
                            {{ header.text }}
                            <v-icon
                                size="18"
                                color="orange"
                                style="position: absolute"
                                v-if="showSumUp"
                                @click.stop="
                                    () =>
                                        calculateTooltipNumber(
                                            header.text,
                                            header.value
                                        )
                                "
                                >mdi-calculator</v-icon
                            >
                        </template>
                        <template v-slot:item.login="{ item }">
                            <div
                                :class="{
                                    'link-text': showHomeLoginDetailSummary,
                                }"
                                @click="handleLoginClick(item)"
                            >
                                {{ item.login }}
                            </div>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        small
                                        v-bind="attrs"
                                        v-on="on"
                                        v-show="item.isInPercentageMode"
                                        >mdi-brightness-percent</v-icon
                                    >
                                </template>
                                <span>Percent Hedge</span>
                            </v-tooltip>
                        </template>
                    </v-data-table>
                </template>

                <SnapshotTab
                    v-if="loadSnapshot"
                    target="symbol"
                    :book="selectedBook"
                    :symbol="symbol"
                />

                <v-card-actions />
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="dialogSumUp"
            height="300"
            width="500"
            v-if="showSumUp"
            :hide-overlay="false"
            :persistent="true"
        >
            <v-card class="pt-0">
                <v-app-bar
                    flat
                    color="rgba(0, 0, 0, 0)"
                    style="background: rgb(26, 57, 88)"
                >
                    <v-toolbar-title class="text-h6 white--text pl-0"
                        >Sum up result:</v-toolbar-title
                    >

                    <v-spacer></v-spacer>

                    <v-btn
                        color="white"
                        icon
                        @click="
                            () => {
                                dialogSumUp = false;
                            }
                        "
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-app-bar>
                <div v-html="htmlResult" class="pa-4"></div>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import deepClone from "deep-clone";
import SnapshotTab from "./ComponentSnapshotTab";
import introJs from "intro.js";
import { TutorialConstant } from "@assets/tutorialConstant";
import { getToken } from "@utils/auth";
import { snackbar } from "@components/mixins/snackbar";
import dayjs from "dayjs";

export default {
    name: "SymbolDetailDialog",
    mixins: [snackbar],
    components: {
        SnapshotTab,
    },
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        symbol: {
            type: String,
            default: "",
        },
        symbolDetailArray: {
            type: Array,
            default: () => [],
        },
        showHomeLoginDetailSummary: {
            type: Boolean,
            default: false,
        },
        tableLoading: {
            type: Boolean,
            default: false,
        },
        selectedBook: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            tutorialConstant: TutorialConstant,
            tab: "trades",
            dialogSumUp: false,
            searchLogin: "",
            fullscreen: false,
            anIncreasingNumberForSub: 1,
            subHeader: null,
            showSumUp: false,
            htmlResult: "",
            showSymbolSnapshot: false,
            loadSnapshot: false,
            loadTrades: true,
            dialogWidth: 1200,
            allSymbolDownloadLoading:false,
            allowDownloadAll: false,
        };
    },
    computed: {
        ...mapState("Home", [
            "subTableHeader",
            "homeSymbolTutorial",
            "homeSymbolSnapshotTutorial",
        ]),
        computedSymbolDetailObject() {
            if (this.searchLogin) {
                return this.symbolDetailArray.filter((item) =>
                    item.login
                        .toUpperCase()
                        .includes(this.searchLogin.toUpperCase())
                );
            }
            return this.symbolDetailArray;
        },
        isNotIBPage() {
            return this.$route.path !== "/ib-monitor";
        },
    },
    watch: {
        visible(val) {
            if (!val) {
                this.$nextTick(() => {
                    this.searchLogin = "";
                    if (!this.VUETIFY_BREAKPOINT.mobile) {
                        this.fullscreen = false;
                    }
                });
            } else {
                this.changeView("trades");
            }
        },
        "VUETIFY_BREAKPOINT.mobile": {
            immediate: true,
            handler(val) {
                if (val) {
                    this.fullscreen = true;
                }
            },
        },
        homeSymbolTutorial(nv) {
            if (!!nv) {
                this.getSymbolTutorial()
                    .oncomplete(() =>
                        this.updateHomeSymbolTutorialAction(false)
                    )
                    .onexit(() => this.updateHomeSymbolTutorialAction(false))
                    .start();
            }
        },
        homeSymbolSnapshotTutorial(nv) {
            if (!!nv) {
                this.getSnapshotTutorial()
                    .oncomplete(() =>
                        this.updateHomeSymbolSnapshotTutorialAction(false)
                    )
                    .onexit(() =>
                        this.updateHomeSymbolSnapshotTutorialAction(false)
                    )
                    .start();
            }
        },
    },
    mounted() {
        const userFunction = JSON.parse(localStorage.getItem("permission"))
            ?.frontPermission?.book?.function;
        if (userFunction.includes("devSumUp")) {
            this.showSumUp = true;
        }
        if (userFunction.includes("symbolSnapshot")) {
            this.showSymbolSnapshot = true;
        }

        const localSavedSubHeader = localStorage.getItem("subHeader");
        if (localSavedSubHeader) {
            this.subHeader = JSON.parse(localSavedSubHeader);
        } else {
            this.subHeader = deepClone(this.subTableHeader);
        }
        if (userFunction.includes("allowDownloadAll")) {
            this.allowDownloadAll = true;
        }
    },
    methods: {
        ...mapActions("Home", [
            "updateHomeSymbolTutorialAction",
            "updateHomeSymbolSnapshotTutorialAction",
        ]),
        startTutorial() {
            if (this.tab == 0) {
                this.updateHomeSymbolTutorialAction(true);
            }
            if (this.tab == 1) {
                this.updateHomeSymbolSnapshotTutorialAction(true);
            }
        },
        async requestAllSymbolDownload(params) {
            try {
                let url =
                    document.querySelector("body").getAttribute("apiurl") ||
                    process.env.VUE_APP_ENDPOINT;
                url = url + "/api/v1/statistic/download-all-symbol-snapshot";
                const response = await fetch(url, {
                    method: 'POST',
                    body: JSON.stringify(params),
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer " + getToken("token")
                    }
                });

                // Check if the response is ok
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                return response; // This should be a Response object
            } catch (error) {
                console.error('Error in requestAllSymbolDownload:', error);
                throw error;
            }
        },
        downloadAllSymbolSnapshot() {
            this.allSymbolDownloadLoading = true
            this.snackBarSuccess("Request has been sent, download will be automatically generate once file is ready.");
            const timeNow = dayjs()
                .tz("US/Eastern")
                .add(7, "hour")
                .add(-1,"minute")
                .format("YYYY_MM_DD HH_mm_00")

            const params = {
                request_time: timeNow,
                selected_symbol: this.symbol
            }
            this.requestAllSymbolDownload(params)
                .then(async (res) => {
                if (res && typeof res.blob === 'function') {
                    const blob = await res.blob();
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = "Symbol Summary ("+this.symbol+")- "+timeNow+'.xlsx';
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(url);
                } else {
                    throw new Error('The response is not a valid Blob');
                }
                this.allSymbolDownloadLoading = false
            })
            .catch((error) => {
                console.log(error);
                this.allSymbolDownloadLoading = false
                this.snackBarDanger("Request failed, please try again later.");
            });
        },
        getSymbolTutorial() {
            return introJs().setOptions({
                steps: [
                    {
                        element: document.getElementById("symbolTabChanging"),
                        title: this.tutorialConstant["homeSymbolDialog"][
                            "symbolTabChanging"
                        ]["title"],
                        intro: this.tutorialConstant["homeSymbolDialog"][
                            "symbolTabChanging"
                        ]["intro"],
                    },
                    {
                        element: document.getElementById("symbolTabSearch"),
                        title: this.tutorialConstant["homeSymbolDialog"][
                            "symbolTabSearch"
                        ]["title"],
                        intro: this.tutorialConstant["homeSymbolDialog"][
                            "symbolTabSearch"
                        ]["intro"],
                    },
                    {
                        element: document.getElementById("symbolDetailTable"),
                        title: this.tutorialConstant["homeSymbolDialog"][
                            "symbolDetailTable"
                        ]["title"],
                        intro: this.tutorialConstant["homeSymbolDialog"][
                            "symbolDetailTable"
                        ]["intro"],
                    },
                ],
            });
        },
        getSnapshotTutorial() {
            return introJs().setOptions({
                steps: [
                    {
                        element: document.getElementById(
                            "symbolTabTimeSelection"
                        ),
                        title: this.tutorialConstant[
                            "homeSymbolSnapshotDialog"
                        ]["symbolTabTimeSelection"]["title"],
                        intro: this.tutorialConstant[
                            "homeSymbolSnapshotDialog"
                        ]["symbolTabTimeSelection"]["intro"],
                    },
                    {
                        element: document.getElementById(
                            "symbolTabSnapshotTable"
                        ),
                        title: this.tutorialConstant[
                            "homeSymbolSnapshotDialog"
                        ]["symbolTabSnapshotTable"]["title"],
                        intro: this.tutorialConstant[
                            "homeSymbolSnapshotDialog"
                        ]["symbolTabSnapshotTable"]["intro"],
                    },
                ],
            });
        },
        calculateTooltipNumber(title, type) {
            const sum = this.computedSymbolDetailObject.reduce(
                (total, currentValue) =>
                    total +
                    parseFloat(
                        typeof currentValue[type] === "string"
                            ? currentValue[type].replace(",", "")
                            : currentValue[type]
                    ),
                0
            );
            console.log(sum);
            this.htmlResult =
                "<h1 class='mb-2'>" + title + ": " + sum.toFixed(2) + "</h1>";
            this.dialogSumUp = true;
            return;
        },
        handleClose() {
            this.$emit("update:visible", false);
        },
        toggleFullscreen() {
            this.fullscreen = !this.fullscreen;
        },
        sortSubHeadersAndUpdateTheKey(evt) {
            const headersTmp = this.subHeader;
            const oldIndex = evt.oldIndex;
            const newIndex = evt.newIndex;
            if (newIndex >= headersTmp.length) {
                let k = newIndex - headersTmp.length + 1;
                while (k--) {
                    headersTmp.push(undefined);
                }
            }
            headersTmp.splice(newIndex, 0, headersTmp.splice(oldIndex, 1)[0]);
            localStorage.setItem("subHeader", JSON.stringify(headersTmp));
            this.anIncreasingNumberForSub += 1;
        },
        handleLoginClick(item) {
            this.$emit("login-click", item);
        },
        customSort(items, sortBy, sortDesc) {
            items.sort((a, b) => {
                if (sortBy[0] === "closedProfitEOD" || sortBy[0] === "net") {
                    if (!sortDesc[0]) {
                        return (
                            Math.abs(a.closedProfitEOD.replaceAll(",", "")) -
                            Math.abs(b.closedProfitEOD.replaceAll(",", ""))
                        );
                    }
                    if (sortDesc[0]) {
                        return (
                            Math.abs(b.closedProfitEOD.replaceAll(",", "")) -
                            Math.abs(a.closedProfitEOD.replaceAll(",", ""))
                        );
                    }
                } else if (sortBy[0] === "login") {
                    if (!sortDesc[0]) {
                        return a[sortBy] < b[sortBy] ? -1 : 1;
                    } else {
                        return b[sortBy] < a[sortBy] ? -1 : 1;
                    }
                } else {
                    if (!sortDesc[0]) {
                        return (
                            a[sortBy]?.toString().replaceAll(",", "") -
                            b[sortBy]?.toString().replaceAll(",", "")
                        );
                    }
                    if (sortDesc[0]) {
                        return (
                            b[sortBy]?.toString().replaceAll(",", "") -
                            a[sortBy]?.toString().replaceAll(",", "")
                        );
                    }
                }
            });
            return items;
        },
        changeView(tab) {
            this.loadSnapshot = false;
            this.loadTrades = false;
            if (tab === "snapshot") {
                this.loadSnapshot = true;
                this.dialogWidth = 800;
            }
            if (tab === "trades") {
                this.loadTrades = true;
                this.dialogWidth = 1200;
            }
            this.tab = tab;
        },
    },
};
</script>
<style lang="scss" scoped>
.link-text {
    color: #2196f3;
    cursor: pointer;
    text-decoration: underline;
}
</style>
