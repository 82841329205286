import request from "@utils/request";

export function getLoginSnapshotData(query) {
    return request({
        url: "snapshot/login",
        method: "post",
        data: query,
    });
}

export function getSymbolSnapshotData(query) {
    return request({
        url: "snapshot/symbol",
        method: "post",
        data: query,
    });
}
